<script>
import TablePage from '@/found/components/table_page';
import request from '@/found/utils/request';

export default {
  extends: TablePage,
  components: {
  },
  data() {
    return {
      formConfig: {},
      searchFormData: {},
      modalConfig: {
        title: '新增',
        visible: false,
        type: 'Modal',
      },
    };
  },
  mounted() {
    this.getConfigList('terminal_orders_list');
  },
  methods: {

  },
};
</script>
